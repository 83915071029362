import { render, staticRenderFns } from "./Pi-Drag-N-Drop.vue?vue&type=template&id=0f612a4d&lang=pug"
import script from "./Pi-Drag-N-Drop.vue?vue&type=script&lang=js"
export * from "./Pi-Drag-N-Drop.vue?vue&type=script&lang=js"
import style0 from "./Pi-Drag-N-Drop.vue?vue&type=style&index=0&id=0f612a4d&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.31_babel-core@7.0.0-bridge.0_@babel+core@7.24.7__css_22zjzvyqtxplcafnycddq7fdra/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports